/*!
 * Minimal Mistakes Jekyll Theme 4.22.0 by Michael Rose
 * Copyright 2013-2020 Michael Rose - mademistakes.com | @mmistakes
 * Licensed under MIT (https://github.com/mmistakes/minimal-mistakes/blob/master/LICENSE)
*/


$right-sidebar-width-narrow: 200px !default;
$right-sidebar-width: 250px !default;
$right-sidebar-width-wide: 300px !default;

/*
scheme: "iA Light"
author: "iA Inc. (modified by aramisgithub)"
https://github.com/arzg/base16-ia-scheme/blob/master/ia-light.yaml
*/
$base00: #f6f6f6 !default;
$base01: #dedede !default;
$base02: #bde5f2 !default;
$base03: #898989 !default;
$base04: #767676 !default;
$base05: #181818 !default;
$base06: #e8e8e8 !default;
$base07: #f8f8f8 !default;
$base08: #9c5a02 !default;
$base09: #c43e18 !default;
$base0a: #c48218 !default;
$base0b: #38781c !default;
$base0c: #2d6bb1 !default;
$base0d: #48bac2 !default;
$base0e: #a94598 !default;
$base0f: #8b6c37 !default;

/* Variables */
@import "minimal-mistakes/variables";

/* Mixins and functions */
@import "minimal-mistakes/vendor/breakpoint/breakpoint";
@include breakpoint-set("to ems", true);
@import "minimal-mistakes/vendor/magnific-popup/magnific-popup"; // Magnific Popup
@import "minimal-mistakes/vendor/susy/susy";
@import "minimal-mistakes/mixins";

/* Core CSS */
@import "minimal-mistakes/reset";
@import "minimal-mistakes/base";
@import "minimal-mistakes/forms";
@import "minimal-mistakes/tables";
@import "minimal-mistakes/animations";

/* Components */
@import "minimal-mistakes/buttons";
@import "minimal-mistakes/notices";
@import "minimal-mistakes/masthead";
@import "minimal-mistakes/navigation";
@import "minimal-mistakes/footer";
@import "minimal-mistakes/search";
@import "minimal-mistakes/syntax";

/* Utility classes */
@import "minimal-mistakes/utilities";

/* Layout specific */
@import "minimal-mistakes/page";
@import "minimal-mistakes/archive";
@import "minimal-mistakes/sidebar";
@import "minimal-mistakes/print";
